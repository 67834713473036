.plugin-landing-page {
	// variables
	$black: #1a1a1a;
	$blue: #0066ff;
	$bg-dark: #13151a;

	// mixins
	@mixin container {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 24px;
	}

	@mixin card-base {
		background: rgba(255, 255, 255, 0.05);
		border-radius: 16px;
		padding: 32px;
	}

	.container {
		@include container;
	}

	.desc {
		text-align: center;
	}

	background: $bg-dark;
	color: white;
	line-height: 1.6;

	h1,
	h2,
	h3 {
		font-weight: bold;
		line-height: 1.4;
	}

	img {
		vertical-align: top;
	}

	.btn-xl {
		padding-left: 40px;
		padding-right: 40px;
		border-radius: 48px;
	}

	// common elements
	.badge {
		display: inline-block;
		background: rgba($secondary500, 0.1);
		border: 1px solid $secondary500;
		color: $secondary500;
		padding: 8px 16px;
		border-radius: 24px;
		margin-bottom: 24px;
	}

	.early-tag {
		// font-size: 14px;
		padding: 8px 16px;
		border-radius: 4px;
		opacity: 0.8;
		font-weight: normal;
	}

	.button {
		display: inline-block;
		background: $blue;
		color: white;
		padding: 16px 32px;
		border-radius: 8px;
		text-decoration: none;
		font-weight: bold;
		transition: transform 0.2s;

		&:hover {
			transform: translateY(-2px);
		}
	}

	// section base
	section {
		padding: 100px 0;

		h2 {
			font-size: 2rem;
			text-align: center;
			margin-bottom: 2rem;
		}
	}

	// Hero Section
	.hero {
		background: $black;
		text-align: center;

		h1 {
			font-size: 2.5rem;
			margin-bottom: 2rem;
			font-weight: 700;
		}

		.hero-description {
			font-size: 1.25rem;
			margin-bottom: 2rem;
		}
	}

	// Features Section
	.features {
		background: linear-gradient(180deg, $black 0%, $bg-dark 100%);

		.features-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 32px;
		}

		.feature-card {
			@include card-base;
			transition: transform 0.3s;

			svg {
				display: block;
				margin-bottom: 8px;
			}

			&:hover {
				transform: translateY(-8px);
			}

			.feature-icon {
				width: 48px;
				height: 48px;
				background: $blue;
				border-radius: 12px;
				margin-bottom: 24px;
			}

			h3 {
				margin-bottom: 1rem;
				font-size: 22px;
			}
		}
	}

	// Target Users Section
	.target-users {
		background: linear-gradient(to bottom, #13151a 0%, #13151a 40%, #0a0a0a 70%, #000 100%);

		.users-grid {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 24px;
		}

		.user-card {
			@include card-base;

			h3 {
				margin-bottom: 1rem;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					display: flex;
					align-items: center;
					gap: 8px;
					&:before {
						content: "";
						display: block;
						width: 4px;
						height: 4px;
						border-radius: 1px;
						background-color: $secondary500;
					}
				}
			}
		}
	}

	// AI Technology Section
	.ai-tech {
		background: linear-gradient(180deg, #003db1 0%, #1364ff 100%);
		text-align: center;

		.tech-description {
			font-size: 1.25rem;
			margin-bottom: 1.5rem;
		}

		.tech-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 32px;
		}

		.tech-card {
			background: rgba(255, 255, 255, 0.1);
			@include card-base;
			h3 {
				margin-bottom: 4px;
			}
		}

		.tech-visual {
			margin-top: 20px;
			.img-cert1 {
				max-width: 465px;
			}
			.img-cert2 {
				max-width: 391px;
			}
			.tech-cert {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				gap: 32px;
			}
			.screen-container {
				display: inline-block;
				vertical-align: top;
				position: relative;

				.youtube-movie {
					position: absolute;
					width: 95.52%;
					top: 3%;
					bottom: 3%;
					aspect-ratio: 16 / 9.7;
					left: 2.24%;
					right: 2.24%;
				}
			}
			.img-a11y {
				margin-top: -1px;
				max-width: 1000px;
			}
		}
	}

	// Process Section
	.process {
		background: #0066ff0d;

		.timeline {
			position: relative;
			max-width: 800px;
			margin: 40px auto 0;
			padding: 60px 0 100px;

			&:before {
				content: "";
				position: absolute;
				left: 50%;
				width: 2px;
				height: 100%;
				background: linear-gradient(to bottom, $bg-dark 0%, $blue 5%, $blue 100%);
				transform: translateX(-50%);
				top: 0;
			}
		}

		.timeline-item {
			margin-bottom: 40px;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.timeline-content {
			width: calc(50% - 30px);
			@include card-base;
			position: relative;
		}

		.timeline-item:nth-child(odd) .timeline-content {
			margin-left: calc(50% + 30px);
		}

		.timeline-number {
			position: absolute;
			width: 36px;
			height: 36px;
			background: $secondary500;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			color: $black;
			left: 50%;
			top: 50%;
			margin-top: -18px;
			transform: translateX(-50%);
			z-index: 1;

			/* 그라데이션 배경 설정 */
			background: radial-gradient(
				circle at center,
				$secondary500 0%,
				$secondary500 30%,
				rgba(19, 21, 26, 0.6) 70%,
				rgba(19, 21, 26, 0.4) 90%,
				#13151a 100%
			);
			box-shadow: 0 0 15px rgba($secondary500, 0.4);
		}
	}

	// Partners Section
	.partners {
		background: linear-gradient(to bottom, #13151a 0%, #0a0a0a 100%);
		text-align: center;
		padding: 100px 0 120px;

		h2 {
			margin-bottom: 1rem;
		}

		.partners-subtitle {
			max-width: 700px;
			margin: 0 auto 60px;
			font-size: 1.1rem;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 300;
		}

		.partners-showcase {
			display: flex;
			flex-direction: column;
			gap: 60px;
		}

		.partners-group {
			position: relative;
		}

		.partners-header {
			margin-bottom: 20px;
		}

		.partners-badge {
			display: inline-block;
			background: rgba($secondary500, 0.05);
			color: #fff;
			padding: 8px 20px;
			border-radius: 30px;
			font-size: 14px;
			font-weight: 400;
			line-height: 130%; /* 18.2px */
			background: rgba(255, 255, 255, 0.1);
		}

		.partners-list {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			row-gap: 8px;
			column-gap: 60px;
			max-width: 860px;
			margin: 0 auto;
			position: relative;

			// &:after {
			// 	content: "";
			// 	position: absolute;
			// 	bottom: -30px;
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	width: 60px;
			// 	height: 1px;
			// 	background: rgba(255, 255, 255, 0.1);
			// }
		}

		.partners-group:last-child .partners-list:after {
			display: none;
		}

		.partner-item {
			img {
				// height: 61.5px;
				height: 82px;
			}
		}

		.partners-metrics {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}

		.metric-item {
			background: rgba(255, 255, 255, 0.03);
			// border: 1px solid rgba(255, 255, 255, 0.1);
			border-radius: 20px;
			padding: 24px 40px;
			min-width: 200px;
			transition: all 0.3s ease;
		}

		.metric-number {
			font-size: 2.5rem;
			font-weight: 700;
			color: white;
			line-height: 1;
			margin-bottom: 8px;

			.metric-plus {
				color: $secondary500;
				font-size: 2rem;
				vertical-align: top;
				position: relative;
				top: -5px;
			}
		}

		.metric-label {
			font-size: 1rem;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 300;
		}

		@include media-breakpoint-down(md) {
			.partners-list {
				gap: 40px;
			}

			.partner-item img {
				height: 60px;
			}

			.metric-number {
				font-size: 2.2rem;

				.metric-plus {
					font-size: 1.8rem;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 80px 0 100px;

			.partners-subtitle {
				font-size: 1rem;
				word-break: keep-all;
				margin-bottom: 40px;
			}

			.partners-showcase {
				gap: 50px;
				margin-bottom: 50px;
			}

			.partners-list {
				gap: 8px 28px;
			}

			// .partner-item img {
			// 	height: 28px;
			// }

			.metric-item {
				padding: 20px 30px;
			}

			.metric-number {
				font-size: 2rem;

				.metric-plus {
					font-size: 1.6rem;
				}
			}

			.metric-label {
				font-size: 0.9rem;
			}
		}
	}

	// Benefits Section
	.benefits {
		padding: 100px 0;
		background-color: $bg-dark;
		color: white;

		.section-title {
			margin-bottom: 48px;
			text-align: center;
		}

		.section-desc {
			margin: -32px auto 40px;
			max-width: 600px;
			text-align: center;
			color: rgba(255, 255, 255, 0.8);
		}

		.benefits-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 24px;
			margin-bottom: 60px;

			@include media-breakpoint-down(md) {
				grid-template-columns: 1fr;
			}
		}

		.benefit-card {
			@include card-base;
			position: relative;
			display: flex;
			flex-direction: column;
			transition: transform 0.3s ease, box-shadow 0.3s ease;
			overflow: hidden;
			border: 1px solid rgba(255, 255, 255, 0.1);
			text-align: left;

			&:hover {
				transform: translateY(-8px);
				box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
			}

			&.highlight {
				border-color: $secondary500;
				background: linear-gradient(rgba(19, 21, 26, 0.8), rgba(19, 21, 26, 0.8)),
					linear-gradient(to right bottom, $blue, darken($blue, 20%));
			}

			.benefit-timing {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 16px;
			}

			.benefit-badge {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: rgba($secondary500, 0.2);
				color: $secondary500;
				font-size: 12px;
				font-weight: 700;
				padding: 4px 12px;
				border-radius: 16px;
			}

			.benefit-icon {
				font-size: 24px;
			}

			h3 {
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 20px;
			}

			.benefit-list {
				list-style: none;
				padding: 0;
				margin: 0 0 auto 0;
				flex-grow: 1;

				li {
					position: relative;
					padding-left: 16px;
					margin-bottom: 12px;
					color: rgba(255, 255, 255, 0.9);

					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 10px;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: $secondary500;
					}

					strong {
						display: flex;
						align-items: center;
						color: white;
						font-weight: 600;
					}

					small {
						display: block;
						color: rgba(255, 255, 255, 0.6);
						font-size: 13px;
						margin-top: 2px;
					}

					.value-tag {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						font-size: 11px;
						font-weight: 700;
						color: $secondary500;
						background: rgba($secondary500, 0.1);
						padding: 1px 6px;
						margin-left: 6px;
						border-radius: 4px;
						vertical-align: text-top;
						height: 18px;
						position: relative;
						top: -1px;
						line-height: 1;
					}
				}
			}

			.benefit-action {
				margin-top: 24px;
				padding-top: 16px;
				border-top: 1px solid rgba(255, 255, 255, 0.1);
				font-size: 14px;

				.delivery-time {
					display: block;
					color: rgba(255, 255, 255, 0.7);
				}

				.discount-timer {
					display: block;
					color: $secondary500;
					font-size: 14px;

					strong {
						font-weight: 700;
					}
				}

				.btn {
					margin-top: 8px;
				}
			}
		}

		// 새로운 프로모션 타임라인 스타일
		.promotion-timeline-container {
			position: relative;
			margin-top: 52px;

			.phase-title {
				text-align: center;
				margin-bottom: 2.5rem;
				font-size: 1.75rem;
				font-weight: 700;
				color: white;
			}

			// 스텝 인디케이터 스타일
			.phase-timeline {
				display: flex;
				justify-content: space-between;
				margin: 0 auto;
				position: relative;
				max-width: 800px;

				// 연결선
				&:before {
					content: "";
					position: absolute;
					top: 2rem;
					left: 0;
					width: 100%;
					height: 2px;
					background: rgba($blue, 0.3);
					z-index: 1;
				}

				// 진행 표시줄
				&:after {
					content: "";
					position: absolute;
					top: 2rem;
					left: 0;
					height: 2px;
					background: $secondary500;
					transition: width 0.5s ease;
					z-index: 2;
				}

				&.phase-1-active:after {
					width: 16.67%;
				}
				&.phase-2-active:after {
					width: 50%;
				}
				&.phase-3-active:after {
					width: 83.33%;
				}
				&.completed:after {
					width: 100%;
				}

				.phase-item {
					position: relative;
					z-index: 5;
					width: 33.333%;
					text-align: center;

					.phase-marker {
						width: 4rem;
						height: 4rem;
						margin: 0 auto 1rem;
						background: $bg-dark;
						border: 2px solid rgba($blue, 0.5);
						border-radius: 50%;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all 0.3s ease;

						// 테두리 검은색 제거하고 그림자 효과만 부드럽게
						box-shadow: 0 0 10px rgba($secondary500, 0.2);

						.phase-number {
							font-size: 1.2rem;
							font-weight: 600;
							color: rgba(255, 255, 255, 0.7);
							transition: all 0.3s ease;
						}
					}

					.phase-content {
						padding: 0 0.5rem;

						.phase-name {
							font-size: 1.1rem;
							font-weight: 600;
							margin-bottom: 0.5rem;
							color: rgba(255, 255, 255, 0.7);
							transition: all 0.3s ease;
						}

						.phase-date {
							font-size: 0.9rem;
							color: rgba(255, 255, 255, 0.5);
							margin-bottom: 0.5rem;
						}

						.phase-countdown {
							background: rgba(0, 0, 0, 0.5);
							border-radius: 4px;
							padding: 0.4rem 1rem;
							margin-top: 0.5rem;
							display: inline-block;

							.countdown-label {
								font-size: 0.8rem;
								color: rgba(255, 255, 255, 0.5);
								margin-right: 0.5rem;
							}

							.countdown-value {
								font-size: 0.8rem;
								font-weight: 700;
								color: $secondary500;
							}
						}
					}

					// 완료된 단계
					&.completed {
						.phase-marker {
							background-color: $bg-dark;
							border-color: rgba(255, 255, 255, 0.4);

							// 체크마크 표시 (기존 방식으로 복구)
							.phase-number {
								display: none;
							}

							&:after {
								content: "✓";
								font-size: 1.5rem;
								color: rgba(255, 255, 255, 0.6);
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}

						.phase-name {
							color: rgba(255, 255, 255, 0.6);
						}
					}

					// 활성화된 단계
					&.active {
						.phase-marker {
							background-color: $bg-dark;
							border-color: $secondary500;
							transform: scale(1.1);
							box-shadow: 0 0 15px rgba($secondary500, 0.6);

							.phase-number {
								color: $secondary500;
								font-weight: 800;
							}
						}

						.phase-name {
							color: $secondary500;
							font-weight: 700;
						}
					}
				}
			}

			// CTA 영역
			.promotion-cta {
				text-align: center;
				background: rgba(19, 21, 26, 0.5);
				border-radius: 8px;
				padding: 2rem;
				max-width: 800px;
				margin: 0 auto;

				.urgency-message {
					font-size: 1.1rem;
					margin-bottom: 1.5rem;
					line-height: 1.5;
				}

				.highlight {
					font-weight: 700;
					color: $secondary500;
					font-size: 1.2rem;
				}

				.btn-lg {
					padding: 0.75rem 2rem;
					font-size: 1.1rem;
					transition: all 0.3s ease;

					&:hover {
						transform: translateY(-2px);
						box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
					}
				}

				.ended-message {
					font-size: 1.1rem;
					color: rgba(255, 255, 255, 0.7);
					margin: 1rem 0;
				}
			}

			// 모든 단계가 완료된 경우 스타일
			&.all-phases-completed {
				.phase-timeline {
					opacity: 0.7;

					// 배경 라인 색상 변경 - 더 옅게
					&:before {
						background: rgba(255, 255, 255, 0.15);
					}

					// 진행 표시줄 색상 변경 (PC 버전) - 더 옅게
					&:after {
						width: 100%;
						background: rgba(255, 255, 255, 0.2);
					}

					// 각 단계 항목에 완료 스타일 적용
					.phase-item {
						.phase-marker {
							border-color: rgba(255, 255, 255, 0.4);

							// 숫자 숨김 처리 추가
							.phase-number {
								display: none !important;
							}

							&:after {
								content: "✓"; // 체크박스 유지
								font-size: 1.5rem;
								color: rgba(255, 255, 255, 0.6);
								background-color: $bg-dark;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}

						// 모든 라인을 더 옅은 색상으로 통일
						&:not(:last-child)::after {
							background: rgba(255, 255, 255, 0.15) !important;
						}
					}
				}
			}

			// 스태퍼와 스탬프를 감싸는 컨테이너 추가
			.timeline-stamp-container {
				position: relative;
				width: 100%;
			}

			// 타임라인을 컨테이너 안으로 이동
			.phase-timeline {
				position: relative;
				z-index: 1;
			}

			// 마감 완료 메시지를 스탬프 스타일로 변경
			.promotion-completed-message {
				text-align: center;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(-5deg);
				padding: 1.5rem 2.5rem;
				background: rgba(255, 0, 0, 0.08);
				border-radius: 8px;
				max-width: 400px;
				border: 6px double rgba(255, 0, 0, 0.7);
				box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
				z-index: 100;
				backdrop-filter: blur(2px);
				white-space: nowrap;

				// 고무 도장 잉크 질감 추가
				background-image: radial-gradient(rgba(255, 0, 0, 0.05) 15%, transparent 16%),
					radial-gradient(rgba(255, 0, 0, 0.05) 15%, transparent 20%);
				background-size: 12px 12px;
				background-position: 0 0, 6px 6px;

				// 잉크 번짐 효과
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: radial-gradient(
						ellipse at center,
						rgba(255, 0, 0, 0.05) 0%,
						rgba(255, 0, 0, 0.15) 90%,
						rgba(255, 0, 0, 0.2) 100%
					);
					z-index: -1;
					opacity: 0.5;
					mix-blend-mode: multiply;
					border-radius: 4px;
				}

				h4 {
					color: rgba(255, 0, 0, 0.7);
					font-size: 1.4rem;
					margin-bottom: 0.3rem;
					line-height: 1.2;
					font-weight: 700;
					// 고무 도장 텍스트 느낌
					text-shadow: 1px 1px 0 rgba(255, 0, 0, 0.1);
				}

				p {
					color: rgba(255, 0, 0, 0.6);
					font-size: 1rem;
					line-height: 1.3;
					font-weight: 700;
					// 고무 도장 텍스트 느낌
					text-shadow: 1px 1px 0 rgba(255, 0, 0, 0.1);
				}
			}

			// 스탬프가 있을 때 타임라인 흐려지게
			&.all-phases-completed .phase-timeline {
				opacity: 0.4;
			}
		}
	}

	// CTA Section
	.cta {
		text-align: center;
		background: #000;

		.cta-description {
			font-size: 1.25rem;
			margin-bottom: 1.5rem;
		}
	}

	@include media-breakpoint-down(md) {
		.container {
			padding: 0 16px;
		}

		section {
			padding: 80px 0;
		}

		.features {
			word-break: keep-all;
			.features-grid {
				grid-template-columns: repeat(3, 1fr);
				gap: 24px;
			}
		}

		.target-users {
			.users-grid {
				grid-template-columns: repeat(2, 1fr);
				gap: 16px;
			}
		}

		.ai-tech {
			.tech-grid {
				word-break: keep-all;
				grid-template-columns: repeat(3, 1fr);
				gap: 20px;
			}

			.tech-card {
				padding: 20px;
			}

			.tech-visual {
				.tech-cert {
					flex-direction: column;
					align-items: center;
					gap: 0;
				}

				.img-a11y {
					max-width: 100%;
				}
			}
		}

		.process {
			.timeline {
				padding: 40px 0 60px;
				max-width: 400px;
				margin: auto;
				&:before {
					left: 24px;
				}
			}

			.timeline-item {
				margin-bottom: 40px;
			}

			.timeline-content {
				width: calc(100% - 48px);
				margin-left: 48px !important;

				p {
					margin-top: 8px;
					font-size: 14px;
					line-height: 1.4;
					word-break: keep-all;
				}
			}

			.timeline-number {
				left: 24px;
				transform: translateX(-50%);
			}
		}

		.benefits {
			.promotion-timeline-container {
				.phase-title {
					font-size: 1.6rem;
				}

				.phase-timeline {
					flex-direction: column;
					align-items: flex-start;
					max-width: 320px;
					margin: 0 auto;

					&:before {
						top: 0;
						left: 2rem;
						width: 2px;
						height: 100%;
						background: rgba($blue, 0.3);
					}

					&:after {
						top: 0;
						left: 2rem;
						width: 2px;
						height: 0;
					}

					&.phase-1-active:after {
						width: 2px;
						height: 0;
					}
					&.phase-2-active:after {
						width: 2px;
						height: 153px;
					}
					&.phase-3-active:after,
					&.completed:after {
						width: 2px;
						height: 212px;
					}

					.phase-item {
						width: 100%;
						display: flex;
						align-items: flex-start;
						margin-bottom: 2rem;
						text-align: left;

						&:last-child {
							margin-bottom: 1.5rem;
						}

						.phase-marker {
							margin: 0;
							margin-right: 1rem;
						}

						.phase-content {
							padding-top: 0.5rem;
						}

						&:not(:last-child)::after {
							top: 2rem;
							left: 2rem;
							width: 2px;
							height: calc(100% + 2rem);
							transform: none;
						}
					}
				}
			}
		}

		// 기존 .promotion-phase 미디어 쿼리 제거
		.promotion-phase {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		section {
			padding: 60px 0;
		}

		h1 {
			font-size: 1.8rem !important;
		}

		h2 {
			font-size: 1.6rem !important;
		}

		.features {
			.features-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
		}

		.target-users {
			.users-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
		}

		.ai-tech {
			.tech-description {
				word-break: keep-all;
			}

			.tech-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
			.tech-visual {
				.img-cert1,
				.img-cert2 {
					max-width: 80%;
				}
			}
		}

		.benefits {
			padding: 4rem 0;

			.benefits-grid {
				grid-template-columns: 1fr;
			}

			.promotion-timeline-container {
				.promotion-completed-message {
					padding: 1rem 1.5rem;

					h4 {
						font-size: 1.2rem;
					}

					p {
						font-size: 1rem;
					}
				}
			}
		}

		// 모든 카드형 UI의 패딩 조정
		.feature-card,
		.user-card,
		.tech-card,
		.timeline-content,
		.benefit-card {
			padding: 24px;
		}
	}
}

.faq {
	padding: 80px 0;
	background: rgba(255, 255, 255, 0.03);

	h2 {
		text-align: center;
		margin-bottom: 48px;
		font-size: 36px;
	}

	.faq-accordion {
		max-width: 680px;
		margin: 0 auto;
	}

	.faq-item {
		background: rgba(255, 255, 255, 0.05);
		border-radius: 16px;
		margin-bottom: 16px;
		overflow: hidden;
		transition: all 0.3s ease;

		&.active {
			.faq-question {
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);

				.arrow {
					transform: rotate(180deg);
				}
			}

			.faq-answer {
				max-height: none;
				padding: 24px 32px;
				opacity: 1;
			}
		}
	}

	.faq-question {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px 32px;
		background: transparent;
		border: none;
		text-align: left;
		color: $secondary500;
		font-size: 18px;
		font-weight: 700;
		cursor: pointer;

		.arrow {
			transition: transform 0.3s ease;
		}
	}

	.faq-answer {
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		padding: 0 32px;
		transition: all 0.3s ease;

		p {
			margin: 0;
			line-height: 1.6;
			color: rgba(255, 255, 255, 0.8);
		}

		.youtube-movie {
			width: 100%;
			max-width: 520px;
			aspect-ratio: 16 / 9;
			border-radius: 12px;
			box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
		}
	}

	@include media-breakpoint-down(md) {
		padding: 60px 0;

		h2 {
			font-size: 28px;
			margin-bottom: 32px;
		}

		.faq-question {
			padding: 20px 24px;
			font-size: 16px;
		}

		.faq-item {
			.faq-answer {
				padding: 0 24px;
			}

			&.active {
				.faq-answer {
					padding: 20px 24px;
				}
			}
		}
	}
}

.discount-tiers {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 6px;

	.discount-tier {
		font-size: 12px;
		padding: 3px 8px;
		border-radius: 4px;
		background: rgba(255, 255, 255, 0.1);
		color: rgba(255, 255, 255, 0.7);

		&.active {
			background: rgba($secondary500, 0.2);
			color: $secondary500;
			font-weight: 600;
		}
	}
}

.discount-timer {
	margin-top: 4px;
	display: block;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.8);

	strong {
		color: $secondary500;
		font-weight: 700;
	}
}

.video-showcase {
	padding: 80px 0;
	background-color: #f8f9fa;
}

.video-wrapper {
	margin-top: 30px;
	position: relative;
	overflow: hidden;
	border-radius: 12px;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.video-showcase .section-description {
	margin-top: 16px;
	font-size: 18px;
	text-align: center;
	color: #666;
}

.video-showcase h2 {
	text-align: center;
	font-weight: bold;
}

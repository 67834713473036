.header-full {
	position: relative;
	flex: 0 0 auto;
	width: 100%;
	z-index: 200;

	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: $bg-white;

	.header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: $max-width;
		margin: 0 auto;
		padding: 0 40px;
		height: 80px;
		// padding-right: 64px - 16px; // 우측 scrollbar 영역 계산

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 0 1 260px;
		}
	}

	@at-root #logo {
		flex: 0 0 auto;
		line-height: 1;
		height: 32px;

		a {
			display: block;
			height: 100%;
		}

		img {
			width: auto;
			height: 100%;
			vertical-align: top;
		}
	}

	.btn-wrap {
		display: flex;
		align-items: center;
		gap: 6px;

		.btn {
			padding: 0 16px;
			font-size: $font-size-sm;
		}
	}

	// @include media-breakpoint-up(lg) {
	// 	position: sticky;
	// 	top: 0;
	// 	z-index: 10;
	// }

	@include media-breakpoint-down(md) {
		.header-container {
			padding: 0 6px 0 24px;
			height: $mobile-header-height;
		}

		#logo {
			height: 22px;
		}

		.btn-wrap {
			gap: 4px;

			.btn {
				padding: 0 12px;
			}
		}
	}
}

#gnb {
	.label {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 20px;
		padding: 0 8px;
		border-radius: 10px;

		font-size: 12px;
		font-weight: normal;
		line-height: 1;

		color: var(--gray-text-secondary, #4b5563);
		background: var(--gray200, #e5e7eb);

		&.free {
			color: #000;
			background: var(--secondary500, #bafd02);
		}

		&.comingsoon {
			color: #000;
			text-decoration: none;
			width: 4px;
			height: 4px;
			padding: 0;
			margin-top: -10px;
			margin-left: 4px;
			border-radius: 50%;
			overflow: hidden;
			background: var(--secondary500, $red600);
			@include ir;
		}
	}

	.submenu-list {
		li.active > {
			a {
				font-weight: bold;
				// text-decoration: underline;
				text-underline-offset: 3px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		position: relative;
		z-index: 100;

		.gnb-nav-list {
			display: flex;
			gap: 12px;

			> li {
				position: relative;
				display: flex;
				justify-content: center;

				&.active {
					text-decoration: underline;
					text-underline-offset: 6px;
					text-decoration-color: rgba(#003fb7, 0.6);

					> a,
					> .menu {
						color: var(--primary700, #003fb7);
					}
				}

				&:hover,
				&:focus-within {
					> a,
					> .menu {
						color: var(--primary700, #003fb7);
						background: var(--primary100, #eff6ff);
					}
					.lnb-area {
						display: block;
					}
				}

				> a,
				> .menu {
					display: flex;
					align-items: center;
					height: 48px;
					padding: 0px 12px;
					color: var(--black, #000);
					font-size: 16px;
					font-weight: 700;
					border-radius: 8px;
				}
			}

			.lnb-area {
				display: none;
				min-width: 220px;

				position: absolute;
				top: 48px;
				// margin-left: -20px;
				// transform: translateX(-25%);
				padding: 24px 20px;

				border-radius: 12px;
				// border: 1px solid var(--gray300, #d1d5db);
				box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
				background: var(--white, #fff);

				white-space: nowrap;
				font-size: 14px;

				dl + dl {
					margin-top: 20px;
				}

				dt {
					display: flex;
					justify-content: space-between;
					gap: 4px;
					margin-bottom: 8px;
					.text {
						font-weight: bold;
						color: var(--gray-text-secondary, #4b5563);
					}
				}

				a {
					display: flex;
					align-items: center;
					gap: 4px;
					padding: 6px 8px;
					border-radius: 4px;

					color: var(--gray-text-primary, #1f2937);
					font-weight: 500;

					&:hover,
					&:focus {
						background: var(--gray100, #f3f4f6);
					}
				}
				.ico {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

#btn-allmenu {
	width: 48px;
	height: 48px;
}

.allmenu-opened {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		#gnb {
			border-top: 1px solid var(--gray200, #e5e7eb);
			position: fixed;
			top: $mobile-header-height;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1000;

			display: flex;
			flex-direction: column;
			background: #fff;

			overflow-y: auto;

			.gnb-nav-list {
				// padding: 12px 0;

				> li {
					position: relative;

					+ li {
						border-top: 1px solid var(--gray100, #f3f4f6);
					}

					&:hover,
					&:focus-within {
						> a {
							color: var(--primary700, #003fb7);
							background: var(--primary100, #eff6ff);
						}

						.lnb-area {
							display: block;
						}
					}

					> a,
					> .menu {
						display: flex;
						align-items: center;
						padding: 8px 32px;
						width: 100%;
						height: 56px;
						color: var(--black, #000);
						font-size: 16px;
						font-weight: 700;
						border-radius: 8px;
					}
				}

				.lnb-area {
					padding: 12px 8px;
					margin: 0 16px 12px;
					border-radius: 8px;
					white-space: nowrap;
					font-size: 14px;
					font-weight: 500;
					background: var(--gray100, #f3f4f6);

					dl {
						+ dl {
							margin-top: 8px;
							padding-top: 8px;
							border-top: 1px solid #fff;
						}
					}

					dt {
						display: flex;
						justify-content: space-between;
						padding: 8px 12px;
						color: var(--gray500, #6b7280);
					}

					a {
						display: flex;
						align-items: center;
						gap: 12px;
						padding: 8px 12px;
						color: var(--gray-text-primary, #1f2937);
					}

					.ico {
						width: 28px;
						height: 28px;
					}
				}
			}
		}

		#top-banner ~ .header-container {
			#gnb {
				top: 128px;
			}
		}

		#sidebar {
			display: flex;
			top: $mobile-header-height;
		}
	}
}
